import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
    spacing: [0, 9, 18, 36, 44, 50, 60, 70, 80, 90, 100],
    palette: {
        primary: {
            main: '#0D1116',
            light: '#878686',
            dark: '#000000',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#FAFAFA',
            light: '#C2BEBE',
            dark: '#e2e2e2',
            contrastText: '#565656',
        },
        info: {
            main: '#181F28',
            light: '#2f353d',
            dark: '#181818',
            contrastText: '#ffffff',
        },
        success: {
            light: '#70abd0',
            main: '#3C88B7',
            dark: '#295d7d',
            contrastText: '#ffffff',
        },
    },
    typography: {
        h1: {
            fontWeight: 500,
            fontSize: 34,
        },
        h3: {
            fontWeight: 600,
            fontSize: 24,
        },
        h6: {
            fontSize: 12,
            fontWeight: 500,
        },
        body1: {
            fontWeight: 500,
            fontSize: 14,
        },
        subtitle1: {
            fontSize: 10,
            letterSpacing: '0.1em',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    width: '193px',
                    height: '43px',
                    textAlign: 'left',
                    boxShadow: 'none',
                    borderRadius: 4,
                },
            },
        },
    },
});

theme = responsiveFontSizes(theme);

export default theme;
