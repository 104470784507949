import { useNavigate } from 'react-router-dom';
import { OrangeButton } from '../components/Button/Button';
import { FlexColumn, FlexRow } from '../components/Flex/Flex';

export default function NotFoundPage() {
    const navigate = useNavigate();
    return (
        <FlexColumn sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <FlexRow sx={{ maxHeight: '150px' }}>
                <p
                    style={{
                        fontSize: '5rem',
                        fontWeight: '400',
                    }}
                >
                    404
                </p>
            </FlexRow>
            <FlexRow sx={{ maxHeight: '200px' }}>
                <p
                    style={{
                        fontSize: '3.5rem',
                        fontWeight: '400',
                    }}
                >
                    Not <span style={{ background: '#FB8E68', fontWeight: '800', letterSpacing: '2px', padding: "5px 10px"}}>found</span> :(
                </p>
            </FlexRow>
            <OrangeButton
                onClick={() => {
                    navigate('/');
                }}
            >
                {' '}
                Back{' '}
            </OrangeButton>
        </FlexColumn>
    );
}
