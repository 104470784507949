import * as React from 'react';

function SVG(props) {
    return (
        <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                opacity="0.4"
                d="M14.4544 0H17.9772C18.6799 0.00339054 19.3526 0.285715 19.8473 0.784907C20.3419 1.2841 20.6181 1.9593 20.6151 2.66206V6.21274C20.6181 6.91551 20.3419 7.5907 19.8473 8.0899C19.3526 8.58909 18.6799 8.87141 17.9772 8.8748H14.4544C13.7516 8.87141 13.079 8.58909 12.5843 8.0899C12.0896 7.5907 11.8134 6.91551 11.8164 6.21274V2.66206C11.8134 1.9593 12.0896 1.2841 12.5843 0.784907C13.079 0.285715 13.7516 0.00339054 14.4544 0"
            />
            <path d="M14.8517 21.2842C14.1385 21.281 13.4558 20.9948 12.9536 20.4884C12.4515 19.982 12.171 19.2969 12.1738 18.5837V14.981C12.1722 14.6278 12.2402 14.2777 12.3739 13.9507C12.5076 13.6238 12.7044 13.3263 12.9531 13.0754C13.2018 12.8245 13.4974 12.625 13.8232 12.4884C14.1489 12.3518 14.4984 12.2806 14.8517 12.279H18.4249C18.7782 12.2805 19.1277 12.3516 19.4535 12.4881C19.7793 12.6247 20.075 12.8241 20.3237 13.0749C20.5725 13.3257 20.7694 13.6231 20.9032 13.95C21.037 14.277 21.1051 14.627 21.1036 14.9803V18.583C21.1066 19.2963 20.8262 19.9815 20.324 20.4881C19.8218 20.9947 19.139 21.281 18.4257 21.2842H14.8517ZM2.67867 21.2842C1.96544 21.281 1.28266 20.9948 0.780383 20.4884C0.278103 19.982 -0.00257722 19.297 2.71395e-05 18.5837V14.981C-0.00297789 14.2676 0.277525 13.5821 0.779849 13.0754C1.28217 12.5687 1.96518 12.2822 2.67867 12.279H6.2512C6.60445 12.2806 6.95394 12.3518 7.27969 12.4884C7.60545 12.625 7.9011 12.8245 8.14976 13.0754C8.39843 13.3263 8.59524 13.6238 8.72896 13.9507C8.86268 14.2777 8.93068 14.6278 8.9291 14.981V18.5837C8.9319 19.2968 8.65149 19.9817 8.1495 20.4881C7.6475 20.9945 6.96499 21.2808 6.25196 21.2842H2.67867ZM2.67867 9.18661C2.32536 9.18513 1.9758 9.11406 1.64996 8.97746C1.32411 8.84086 1.02837 8.6414 0.779627 8.39049C0.530881 8.13957 0.334003 7.84211 0.200238 7.5151C0.0664722 7.18808 -0.0015599 6.83791 2.71395e-05 6.4846V2.88117C-0.00277716 2.16788 0.277838 1.48268 0.780162 0.976253C1.28248 0.469831 1.96538 0.183656 2.67867 0.180664L6.2512 0.180664C6.60436 0.182146 6.95376 0.253172 7.27946 0.389687C7.60516 0.526202 7.90078 0.725533 8.14945 0.976299C8.39812 1.22706 8.59495 1.52435 8.72873 1.85119C8.8625 2.17803 8.93058 2.52802 8.9291 2.88117V6.48385C8.93078 6.83712 8.86284 7.18727 8.72916 7.51428C8.59548 7.8413 8.39867 8.13876 8.14999 8.38969C7.9013 8.64061 7.60561 8.84008 7.27981 8.97669C6.95401 9.1133 6.60448 9.18437 6.2512 9.18586L2.67867 9.18661Z" />
        </svg>
    );
}

export default SVG;
