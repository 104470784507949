import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FC, useState } from 'react';
import { FlexRow } from '../Flex/Flex';

interface FilterOptionsBarProps {}

export const FilterOptionsBar: FC<FilterOptionsBarProps> = ({}) => {
    const [vehicleType, setVehicleType] = useState<string | undefined>('');
    const [sortType, setSortType] = useState<string | undefined>('');
    const [transmission, setTransmission] = useState<string | undefined>('');
    const [fuelType, setFuelType] = useState<string | undefined>('');

    const handleSortType = (event: SelectChangeEvent) => {
        setSortType(event.target.value as string);
    };

    const handleVehicleType = (event: SelectChangeEvent) => {
        setVehicleType(event.target.value as string);
    };

    const handleChangeFuelType = (event: SelectChangeEvent) => {
        setFuelType(event.target.value as string);
    };

    const handleChangeTransmission = (event: SelectChangeEvent) => {
        setTransmission(event.target.value as string);
    };

    return (
        <FlexRow sx={{ justifyContent: 'flex-end', margin:"0px 10px" }}>
            <FormControl sx={{ width: '200px', marginRight: '10px' }}>
                <InputLabel id="demo-simple-select-label">Sort by</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sortType}
                    label="Sort by"
                    onChange={handleSortType}
                >
                    <MenuItem value={'PRICE_LOW_TO_HIGH'}>Price (low to high)</MenuItem>
                    <MenuItem value={'PRICE_HIGH_TO_LOW'}>Price (high to low)</MenuItem>
                </Select>
            </FormControl>
            <FormControl sx={{ width: '200px', marginRight: '10px' }}>
                <InputLabel id="demo-simple-select-label">Vehicle Type</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={vehicleType}
                    label="Vehicle Type"
                    onChange={handleVehicleType}
                >
                    <MenuItem value={'VEHICLE_TYPE_COUPE'}>Coupe</MenuItem>
                    <MenuItem value={'VEHICLE_TYPE_SUV'}>SUV</MenuItem>
                    <MenuItem value={'VEHICLE_TYPE_SEDAN'}>Sedan</MenuItem>
                </Select>
            </FormControl>
            <FormControl sx={{ width: '200px', marginRight: '10px' }}>
                <InputLabel id="demo-simple-select-label">Transmission</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={transmission}
                    label="Transmission"
                    onChange={handleChangeTransmission}
                >
                    <MenuItem value={'TRANSMISSION_AUTOMATIC'}>Automatic</MenuItem>
                    <MenuItem value={'TRANSMISSION_AUTOMATIC'}>Manual</MenuItem>
                </Select>
            </FormControl>
            <FormControl sx={{ width: '200px' }}>
                <InputLabel id="demo-simple-select-label">Fuel Type</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={fuelType}
                    label="Fuel Type"
                    onChange={handleChangeFuelType}
                >
                    <MenuItem value={'FUEL_TYPE_ELECTRIC'}>Electric</MenuItem>
                    <MenuItem value={'FUEL_TYPE_GAS'}>Gas</MenuItem>
                    <MenuItem value={'FUEL_TYPE_MILD_HYBRID'}>Mild Hybrid</MenuItem>
                </Select>
            </FormControl>
        </FlexRow>
    );
};

export default FilterOptionsBar;
