// MUI
import { styled } from '@mui/material/styles';
// Firebase
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import './App.css';
// Auth Context
import { AuthCtx } from './AuthProvider';
// Custom Components
import LoadingPage from './pages/LoadingPage';
import UnauthorizedPage from './pages/UnauthorizedPage';

export interface Activity {
    type: string;
    code: string;
}

export interface ItemMatrix {
    code: string;
    type: string;
    item: number[];
}

const PageContainer = styled('div')({
    left: '280px',
    right: '0',
    bottom: '0',
    display: 'flex',
    flexDirection: 'column',
});

const StyledPageContainer = styled(PageContainer)({
    flex: 1,
    display: 'flex',
});
export interface SecureLayoutProps {
    title?: string;
    adminRequired?: boolean;
    permissionRequired?: boolean;
    permissionName?: string;
}

function SecureLayout({
    children,
    permissionRequired,
    permissionName,
}: { children: JSX.Element } & SecureLayoutProps) {
    let location = useLocation();

    const { user, loading: loadingUser, permissions } = React.useContext(AuthCtx);

    if (loadingUser) {
        return <LoadingPage />;
    } else if (!user) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        //	TODO: Uncomment the following line to apply authentication.
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (permissionRequired && permissionName && permissions.indexOf(permissionName) === -1) {
        return <UnauthorizedPage />;
    }

    return (
        <>
            <StyledPageContainer>{children}</StyledPageContainer>
        </>
    );
}

export default SecureLayout;
