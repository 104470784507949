import { Container, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FlexColumn, FlexRow } from '../../components/Flex/Flex';
import NextButton from '../../components/NextButton';
import PageLayout from '../PageLayout/PageLayout';
import FindingBestResultPage from './FindingBestResultPage';
import Tesla from '../../assets/EQA_ambient.png';
import MercedesLogo from '../../assets/mercedes.png';
import { Vehicle } from '../../DataProvider';

interface ResultPageProps {
    onNextButtonPressed?: () => void;
}

const MatchVehicleCard = () => {
    return (
        <FlexColumn
            sx={{
                background: '#F2F4F6',
                position: 'absolute',
                right: '20px',
                top: '30px',
                bottom: '30px',
                zIndex: 5,
                width: '45%',
                boxShadow: '0px 0px 42px rgba(0, 0, 0, 0.16)',
                padding: '30px',
            }}
        >
            <FlexRow>
                <Typography sx={{ fontSize: '2.5rem' }}>
                    <strong>Mercedes-Benz</strong> EQA 250+
                </Typography>
                <div
                    style={{
                        marginLeft: '20px',
                        width: '50px',
                        height: '50px',
                        backgroundImage: `url(${MercedesLogo})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }}
                ></div>
            </FlexRow>
            <FlexRow sx={{ flex: 'none' }}>
                <FlexColumn>
                    <FlexRow>Subscribe</FlexRow>
                    <FlexRow>€ 849 / month*</FlexRow>
                    <div>Best Value For Money</div>
                </FlexColumn>
                <FlexColumn style={{ borderLeft: '1px solid #CCC' }}>
                    <FlexRow>Lease</FlexRow>
                    <FlexRow>€ 1004 / month*</FlexRow>
                </FlexColumn>
                <FlexColumn style={{ borderLeft: '1px solid #CCC' }}>
                    <FlexRow>Finance</FlexRow>
                    <FlexRow>€ 2322 / month*</FlexRow>
                </FlexColumn>
            </FlexRow>
            <div style={{ fontSize: '0.7rem', color: '#666' }}>*starting price</div>
            <FlexRow
                sx={{
                    color: 'white',
                    background: 'black',
                    maxHeight: '60px',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                Show me more
            </FlexRow>
        </FlexColumn>
    );
};

const AltRecommendationVehicle: FC<{
    alt: string;
    vehicle: Vehicle;
}> = ({ alt, vehicle }) => {
    const [altColor, setAltColor] = useState('white');

    useEffect(() => {
        if (alt === 'SUSTAINABLE') {
            setAltColor('#62E1A4');
        }
        if (alt === 'SPORTLY') {
            setAltColor('#EFFB68');
        }
        if (alt === 'LIMITED') {
            setAltColor('#68D8FB');
        }
    }, [alt]);

    return (
        <FlexColumn>
            <div style={{ background: altColor }}>{vehicle.name}</div>
            <div
                style={{
                    marginLeft: '20px',
                    width: '50px',
                    height: '50px',
                    backgroundImage: `url(${MercedesLogo})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }}
            ></div>
        </FlexColumn>
    );
};

const Page: React.FC<ResultPageProps> = ({ onNextButtonPressed }) => {
    const [findingBestResult, setFindingBestResult] = useState(true);
    const navigate = useNavigate();
    const handleNext = () => {
        if (onNextButtonPressed) {
            onNextButtonPressed();
        }
        navigate('/ls1');
    };

    useEffect(() => {
        setTimeout(() => {
            setFindingBestResult(false);
        }, 1500);
    }, []);

    if (findingBestResult) {
        return <FindingBestResultPage />;
    }

    return (
        <PageLayout>
            <Container
                maxWidth="md"
                style={{ padding: '0px', position: 'relative', flex: 1, display: 'flex', flexDirection: 'column' }}
            >
                <FlexColumn sx={{ background: 'white', fontFamily: "'Nanum Gothic', sans-serif", flex: 'none' }}>
                    <p
                        style={{
                            fontSize: '3.5rem',
                            fontWeight: '400',
                            lineHeight: '4rem',
                        }}
                    >
                        Congratulations, we have matched you by 97% with{' '}
                        <span style={{ background: '#FB8E68', fontWeight: '800' }}>Mercedes-Benz EQA</span>
                    </p>
                </FlexColumn>
            </Container>
            <Container
                maxWidth="lg"
                style={{ padding: '0px', position: 'relative', flex: 1, display: 'flex', flexDirection: 'column' }}
            >
                <FlexRow sx={{ position: 'relative' }}>
                    <div
                        style={{
                            width: '60%',
                            backgroundImage: `url(${Tesla})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            height: '460px',
                        }}
                    />
                    <MatchVehicleCard />
                </FlexRow>
            </Container>
            <Container
                maxWidth="md"
                style={{ padding: '0px', position: 'relative', flex: 1, display: 'flex', flexDirection: 'column' }}
            >
                <FlexRow
                    sx={{
                        fontSize: '1.5rem',
                        fontWeight: '600',
                        marginTop: '50px',
                        maxHeight: '60px',
                    }}
                >
                    You might also like
                </FlexRow>
                <FlexRow sx={{ background: 'blue' }}>
                </FlexRow>
            </Container>
        </PageLayout>
    );
};

export default Page;
