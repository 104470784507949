import { Container, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FlexColumn, FlexRow } from '../components/Flex/Flex';
import PageLayout from './PageLayout/PageLayout';

import Tesla from '../assets/tesla.png';
import { useContext, useEffect, useState } from 'react';
import { CaroosyDataCtx, Vehicle } from '../DataProvider';
import { AuthCtx } from '../AuthProvider';

import CustomAppSVG from '../assets/svg/figma_app';
import { getImageDownloadURL } from '../Firebase';
import FilterOptionsBar from '../components/FilterOptionsBar/FilterOptionsBar';
import PageFooter from '../components/PageFooter/PageFooter';

interface AutoTileProps {
    onClick?: () => void;
    onSurveyButtonClick?: () => void;
    vehicle: Vehicle;
}

const AutoTile: React.FC<AutoTileProps> = ({ vehicle, onClick, onSurveyButtonClick }) => {
    const [imageURL, setImageURL] = useState<string | undefined>();

    useEffect(() => {
        const fetch_data = async () => {
            if (vehicle && vehicle.images) {
                const url = await getImageDownloadURL('/vehicles/' + vehicle.images);
                setImageURL(url);
            }
        };
        fetch_data();
    }, [vehicle]);

    const { user } = useContext(AuthCtx);
    return (
        <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0px 5px',
            }}
        >
            <FlexColumn
                sx={{
                    border: '1px solid #EDEDED',
                    margin: '20px',
                    padding: '30px',
                    backgroundColor: 'white',
                    boxShadow: '0px 0px 10px rgba(128, 128,128, 0.05)',
                    position: 'relative',
                    transition: 'all .2s ease-in-out',
                    '&:hover': {
                        boxShadow: '0px 0px 15px rgba(0, 0,0, 0.1)',
                        zIndex: 10,
                    },
                }}
            >
                <div
                    style={{
                        backgroundImage: `url(${imageURL})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        cursor: 'pointer',
                        height: '200px',
                    }}
                    onClick={onClick}
                />
                {user && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            width: '30px',
                            height: '30px',
                            minWidth: '20px',
                            padding: '3px',
                            cursor: 'pointer',
                        }}
                        onClick={onSurveyButtonClick}
                    >
                        <CustomAppSVG fill={'#FB8E68'} />
                    </div>
                )}

                <div
                    style={{
                        fontWeight: '800',
                        fontSize: '1rem',
                        letterSpacing: '2px',
                        padding: '10px 0px',
                    }}
                >
                    {vehicle.brand} {vehicle.name}
                </div>
                <div>
                    <span style={{ color: '#FB8E68', fontSize: '1.5rem', fontWeight: '800' }}>
                        {vehicle.price_lease || vehicle.price_subscribe}
                    </span>{' '}
                    pro Monat
                </div>
            </FlexColumn>
        </Grid>
    );
};

const Page: React.FC = ({ children }) => {
    const { vehicles, setSelectedVehicle, setSurveyParams } = useContext(CaroosyDataCtx);
    const navigate = useNavigate();

    return (
        <PageLayout scrollable displayFindCarButton>
            <Container
                maxWidth="lg"
                style={{ padding: '0px', position: 'relative', display: 'flex', flexDirection: 'column' }}
            >
                <FlexRow
                    style={{
                        fontWeight: '800',
                        fontSize: '1.5rem',
                        letterSpacing: '2px',
                        marginTop: '50px',
                        marginLeft: '20px',
                    }}
                >
                    Autos
                </FlexRow>
                <FilterOptionsBar />
                <Grid container spacing={1} sx={{ marginTop: '20px' }}>
                    {vehicles.map((vehicle, index) => {
                        return (
                            <AutoTile
                                key={`vehicle_${index}`}
                                vehicle={vehicle}
                                onClick={() => {
                                    setSelectedVehicle(vehicle);
                                    navigate(`/details/${vehicle.brand}_${vehicle.name}`);
                                }}
                                onSurveyButtonClick={() => {
                                    console.log(vehicle);
                                    setSelectedVehicle(vehicle);
                                    setSurveyParams({
                                        ahb_id: vehicle.ahb_id,
                                        vehicle_name: vehicle.name,
                                        brand: vehicle.brand,
                                    });
                                    navigate(`/ls/intro?svy=true&vehicle=${vehicle.ahb_id}`);
                                }}
                            />
                        );
                    })}
                </Grid>
                <PageFooter />
            </Container>
        </PageLayout>
    );
};

export default Page;
