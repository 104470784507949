import { Box, Checkbox, Container, FormControlLabel, FormGroup, Grid, Slider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FlexColumn, FlexRow } from '../../components/Flex/Flex';
import NextButton from '../../components/NextButton';
import PageLayout from '../PageLayout/PageLayout';

interface HardFactsPageProps {}

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const marks = [
    {
        value: 0,
        label: '0 €',
    },
    {
        value: 100,
        label: '100 €',
    },
    {
        value: 200,
        label: '200 €',
    },
    {
        value: 300,
        label: '300 €',
    },
    {
        value: 400,
        label: '400 €',
    },
    {
        value: 500,
        label: '500 €',
    },
    {
        value: 600,
        label: '600 €',
    },
    {
        value: 700,
        label: '700 €',
    },
    {
        value: 800,
        label: '800 €',
    },
    {
        value: 900,
        label: '900 €',
    },
    {
        value: 1000,
        label: '1000 €',
    },
    {
        value: 1100,
        label: '> 1000 €',
    },
];

const Page: React.FC<HardFactsPageProps> = ({ children }) => {
    const navigate = useNavigate();

    return (
        <PageLayout scrollable>
            <Container
                maxWidth="md"
                style={{
                    padding: '0px',
                    position: 'relative',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '80px',
                }}
            >
                <div style={{ display: 'flex' }}>
                    <p
                        style={{
                            fontSize: '3.5rem',
                            fontWeight: '400',
                            lineHeight: '4rem',
                        }}
                    >
                        Almost there - just a few final questions about a few
                        <br />
                        <span style={{ background: '#FB8E68', fontWeight: '800', letterSpacing: '2px' }}>
                            hard facts
                        </span>{' '}
                        to get your perfect match.
                    </p>
                    <FlexColumn
                        sx={{
                            minWidth: '70px',
                            maxWidth: '70px',
                            justifyContent: 'center',
                            fontFamily: "'Nanum Gothic', sans-serif",
                            fontWeight: 600,
                        }}
                    >
                        Step 8 / 9
                    </FlexColumn>
                </div>

                <div
                    style={{
                        fontSize: '1.5rem',
                        fontWeight: '600',
                    }}
                >
                    How would your prefer to pay?
                </div>

                <FormGroup sx={{ flexDirection: 'row' }}>
                    <FormControlLabel control={<Checkbox />} label="All at once" />
                    <FormControlLabel control={<Checkbox />} label="Monthly payments" />
                </FormGroup>

                <div
                    style={{
                        fontSize: '1.2rem',
                        fontWeight: '400',
                        marginTop: '30px',
                    }}
                >
                    What budget do you have in mind?
                </div>

                <Box>
                    <Slider
                        defaultValue={400}
                        step={100}
                        marks={marks}
                        min={0}
                        max={1100}
                        aria-label="Always visible"
                        getAriaValueText={val => `${val}€`}
                        sx={{
                            '.MuiSlider-markLabel': {
                                fontSize: '0.6rem',
                                color: '#AAA',
                            },
                            '.MuiSlider-markLabelActive': {
                                color: '#444',
                            },
                        }}
                    />
                </Box>
                <div
                    style={{
                        fontSize: '1.5rem',
                        fontWeight: '600',
                        marginTop: '50px',
                    }}
                >
                    When do you need your car?
                </div>

                <FormGroup sx={{ flexDirection: 'row' }}>
                    <FormControlLabel control={<Checkbox />} label="Now" />
                    <FormControlLabel control={<Checkbox />} label="in 3-6 months" />
                    <FormControlLabel control={<Checkbox />} label="in 3-12 months" />
                    <FormControlLabel control={<Checkbox />} label="Doesn't matter" />
                </FormGroup>

                <div
                    style={{
                        fontSize: '1.5rem',
                        fontWeight: '600',
                        marginTop: '50px',
                    }}
                >
                    How many kilometres do you drive with your car per year?
                </div>

                <FormGroup sx={{ flexDirection: 'row' }}>
                    <FormControlLabel control={<Checkbox />} label="0-5.000 km" />
                    <FormControlLabel control={<Checkbox />} label="5.000 - 10.000 km" />
                    <FormControlLabel control={<Checkbox />} label="10.000 - 15.000 km" />
                    <FormControlLabel control={<Checkbox />} label="> 15.000 km" />
                    <FormControlLabel control={<Checkbox />} label="I don't know" />
                </FormGroup>

                <div
                    style={{
                        fontSize: '1.5rem',
                        fontWeight: '600',
                        marginTop: '50px',
                    }}
                >
                    For how long would you be willing to keep it?
                </div>

                <FormGroup sx={{ flexDirection: 'row' }}>
                    <FormControlLabel control={<Checkbox />} label="< 0.5 year" />
                    <FormControlLabel control={<Checkbox />} label="1 year" />
                    <FormControlLabel control={<Checkbox />} label="2 years" />
                    <FormControlLabel control={<Checkbox />} label="3 years" />
                    <FormControlLabel control={<Checkbox />} label="> 3 years" />
                </FormGroup>

                <NextButton label={'Next'} onClick={() => navigate('/result')} />
            </Container>
        </PageLayout>
    );
};

export default Page;
