import { Container, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FlexColumn, FlexRow } from '../../components/Flex/Flex';

import Tesla from '../../assets/tesla.png';
import PageLayout from '../PageLayout/PageLayout';
import { useContext, useEffect, useState } from 'react';
import { getImageDownloadURL } from '../../Firebase';
import { CaroosyDataCtx } from '../../DataProvider';

interface HardFactsPageProps {}

const Page: React.FC<HardFactsPageProps> = ({ children }) => {
    const { selectedVehicle } = useContext(CaroosyDataCtx);
    const navigate = useNavigate();

    const [imageURL, setImageURL] = useState<string | undefined>();

    useEffect(() => {
        const fetch_data = async () => {
            if (selectedVehicle && selectedVehicle.images) {
                const url = await getImageDownloadURL('/vehicles/' + selectedVehicle.images);
                console.log(url);
                setImageURL(url);
            }
        };
        fetch_data();
    }, [selectedVehicle]);

    return (
        <PageLayout scrollable displayFindCarButton>
            <Container
                maxWidth="lg"
                style={{ padding: '0px', position: 'relative', display: 'flex', flexDirection: 'column' }}
            >
                <FlexRow>
                    <p
                        style={{
                            fontSize: '2.5rem',
                            fontWeight: '400',
                            lineHeight: '3rem',
                            maxWidth: '1000px',
                            padding: '0px 40px',
                        }}
                    >
                        The{' '}
                        <span style={{ background: '#FB8E68', fontWeight: 800, letterSpacing: '2px' }}>
                            {selectedVehicle && selectedVehicle.brand} {selectedVehicle && selectedVehicle.name}
                        </span>{' '}
                        perfectly matches your preferences
                    </p>
                </FlexRow>
                <FlexRow
                    style={{
                        height: '400px',
                        margin: '10px',
                        padding: '20px',
                    }}
                >
                    <div
                        style={{
                            flex: 2,
                            height: '400px',
                            backgroundImage: `url(${imageURL})`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            cursor: 'pointer',
                        }}
                    />
                    <div
                        style={{
                            flex: 1,
                            fontWeight: '800',
                            fontSize: '1rem',
                            letterSpacing: '2px',
                            padding: '10px 0px',
                        }}
                    >
                        {selectedVehicle && selectedVehicle.name}
                    </div>
                    <div>
                        <span style={{ color: '#FB8E68', fontSize: '1.5rem', fontWeight: '800' }}>1299 €</span> pro
                        Monat
                    </div>
                </FlexRow>
                <FlexRow>
                    <FlexColumn sx={{ flex: 2 }}>
                        <div style={{ background: 'purple', height: '700px' }}>
                            <div>Equipment</div>
                        </div>
                        <div style={{ background: 'yellow', height: '700px' }}>
                            <div>Cost Overview</div>
                            <div>This is our recommendation according to your holding period & distance traveled.</div>
                        </div>
                    </FlexColumn>
                    <FlexColumn sx={{ flex: 1, background: 'crimson', height: '500px' }}>
                        <div>Pricing Model</div>
                    </FlexColumn>
                </FlexRow>
            </Container>
        </PageLayout>
    );
};

export default Page;
