import CaroosyImage from '../assets/caroosy_logo.png';
import { Container, styled } from '@mui/material';
import { FlexColumn } from '../components/Flex/Flex';

const PageContainer = styled(Container)({
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: '0px !important',
    position: 'absolute',
});

const CaroosyLogo = styled('div')({
    backgroundImage: `url(${CaroosyImage})`,
    backgroundSize: 'contain',
    width: '200px',
    height: '150px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
});

const FindingBestResultPage: React.FC = () => {
    return (
        <PageContainer>
            <FlexColumn sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <CaroosyLogo />
            </FlexColumn>
        </PageContainer>
    );
};

export default FindingBestResultPage;
