import { Container, Grid, styled } from '@mui/material';
import { FlexRow } from '../../components/Flex/Flex';

import CaroosyImage from '../../assets/caroosy_logo.png';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthCtx } from '../../AuthProvider';

// CUSTOM ICONS
import CustomSignOutSVG from '../../assets/svg/figma_sign_out';
import { CaroosyLogo } from '../../components/CaroosyLogo/CaroosyLogo';

const PageContainer = styled(Container)({
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: '0px !important',
    position: 'absolute',
});

const FindAutoButton = styled('div')({
    background: '#FB8E68',
    backgroundSize: 'contain',
    width: '150px',
    height: '40px',
    margin: 'auto 0',
    marginLeft: '70px',
    cursor: 'pointer',
    display: 'flex',
    color: 'white',
    fontWeight: 600,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '30px',
});

export interface SecureLayoutProps {
    scrollable?: boolean;
    displayFindCarButton?: boolean;
}

const PageLayout: React.FC<SecureLayoutProps> = ({ scrollable, children, displayFindCarButton }) => {
    const navigate = useNavigate();

    const { user, signOut } = useContext(AuthCtx);

    return (
        <PageContainer
            maxWidth={false}
            sx={{
                overflowY: scrollable ? 'scroll' : 'hidden',
            }}
        >
            <FlexRow
                sx={{
                    minHeight: '100px',
                    maxHeight: '100px',
                    borderBottom: '1px solid #000',
                    alignContent: 'flex-start',
                }}
            >
                <Container
                    maxWidth="lg"
                    style={{ padding: '0px', position: 'relative', flex: 1, display: 'flex', alignItems: 'center' }}
                >
                    <CaroosyLogo onClick={() => navigate('/')} />
                    <div style={{ flex: 1 }}></div>
                    {displayFindCarButton && (
                        <FindAutoButton onClick={() => navigate('/ls/intro')}> Find my Auto</FindAutoButton>
                    )}
                    {user && (
                        <div
                            style={{
                                border: '2px solid #FB8E68',
                                stroke: '#FB8E68',
                                strokeWidth: '1pt',
                                width: '40px',
                                height: '40px',
                                minWidth: '40px',
                                padding: '8px',
                                cursor: 'pointer',
                                marginRight: '30px',
                            }}
                            onClick={() => signOut()}
                        >
                            <CustomSignOutSVG fill={'#FB8E68'} />
                        </div>
                    )}
                </Container>
            </FlexRow>
            {children}
        </PageContainer>
    );
};

export default PageLayout;
