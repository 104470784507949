import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { Vehicle } from './DataProvider';

let firebaseConfig = {
    apiKey: 'AIzaSyBkGIRi6UoivyvW2S-PehL-2b2ilMi-wME',
    authDomain: 'ahb-lsc.firebaseapp.com',
    databaseURL: 'https://ahb-lsc-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'ahb-lsc',
    storageBucket: 'ahb-lsc.appspot.com',
    messagingSenderId: '303011295723',
    appId: '1:303011295723:web:be945eea4bf606f6aea9ae',
    measurementId: 'G-MF8TW81VHR',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const database = getDatabase(app);
const firestore = getFirestore(app);

const headerConfig = async (): Promise<{ [key: string]: any }> => {
    if (auth.currentUser) {
        const token = await auth.currentUser.getIdToken();
        return { headers: { Authorization: 'Bearer ' + token } };
    } else {
        return {};
    }
};

export const getAllVehiclesFromFirestore = async (): Promise<Vehicle[]> => {
    const querySnapshot = await getDocs(collection(firestore, 'vehicles'));
    const vehicles: Vehicle[] = [];
    querySnapshot.forEach(doc => {
        vehicles.push(doc.data() as Vehicle);
    });
    return vehicles;
};

const uploadFileToStorage = async (
    fileName: string,
    storagePath: string,
    file: File,
    onLoadingHandler?: (progress: boolean) => void,
    onProgressHandler?: (progress: number) => void,
) => {
    if (onLoadingHandler) onLoadingHandler(true);

    const destPath = `${storagePath}/${fileName}`;

    const storageRef = ref(storage, destPath);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
        'state_changed',
        (snapshot: any) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            if (onProgressHandler) onProgressHandler(progress);
        },
        (error: any) => {
            console.log(error);
            if (onLoadingHandler) onLoadingHandler(false);
            if (onProgressHandler) onProgressHandler(-1);
        },
        async () => {
            if (onLoadingHandler) onLoadingHandler(false);
            getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                console.log('File available at', downloadURL);
            });
        },
    );
};

export const getImageDownloadURL = async (imageRef: string) => {
    const url = await getDownloadURL(ref(storage, imageRef));
    return url;
};

export { app, auth, database, firestore, storage, headerConfig, uploadFileToStorage };
