import { Container } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FlexColumn } from '../../components/Flex/Flex';
import NextButton from '../../components/NextButton';
import { CaroosyDataCtx, Vehicle } from '../../DataProvider';
import PageLayout from '../PageLayout/PageLayout';

interface IntroPageProps {
    onNextButtonPressed?: () => void;
    isSurvey?: boolean;
    surveyVehicle?: Vehicle;
}

const Page: React.FC<IntroPageProps> = ({ onNextButtonPressed,isSurvey }) => {
    const { selectedVehicle }  = useContext(CaroosyDataCtx)
    const navigate = useNavigate();
    const handleNext = () => {
        if (onNextButtonPressed) {
            onNextButtonPressed();
        }
        const surveyParam = isSurvey ? '?svy=true' : '';
        const vehicleParam = isSurvey && selectedVehicle ? `&vehicle=${selectedVehicle.ahb_id}` : '';
        navigate(`/ls/city${surveyParam}${vehicleParam}`);
    };
    return (
        <PageLayout>
            <FlexColumn>
                <Container
                    maxWidth="md"
                    style={{ padding: '0px', position: 'relative', flex: 1, display: 'flex', marginBottom: '80px' }}
                >
                    <FlexColumn sx={{ fontFamily: "'Nanum Gothic', sans-serif" }}>
                        <p
                            style={{
                                fontSize: '3.5rem',
                                fontWeight: '400',
                            }}
                        >
                            Let's find the <br />{' '}
                            <span style={{ background: '#FB8E68', fontWeight: '800' }}>perfect car</span> for you.
                        </p>
                        <div style={{ maxWidth: '70%', fontSize: '0.9rem', lineHeight: '1.5rem', fontWeight: 600 }}>
                            We will ask you 7 simple questions to match you with your perfect car –according to your
                            needs and preferences. It will take you no longer than 3 minutes to get your perfect match.
                            Hit start and enjoy the ride 🎉
                        </div>
                    </FlexColumn>
                </Container>
                <NextButton label={"Let's start"} onClick={handleNext} />
            </FlexColumn>
        </PageLayout>
    );
};

export default Page;
