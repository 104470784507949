import { Grid } from '@mui/material';
import { FC } from 'react';
import { FlexColumn, FlexRow } from '../Flex/Flex';
import { CaroosyLogo } from '../CaroosyLogo/CaroosyLogo';

interface PageFooterProps {}

export const PageFooter: FC<PageFooterProps> = ({}) => {
    return (
        <FlexColumn sx={{ minHeight: '200px', marginTop: '60px' }}>
            <Grid container>
                <Grid item xs={6}>
                    <div>Overview</div>
                    <div>How it works</div>
                    <div>Vehicle Return</div>
                    <div>Insurance & Coverage</div>
                </Grid>
                <Grid item xs={6}>
                    <div>Company</div>
                    <div>About us</div>
                    <div>Sustainability</div>
                    <div>Careers</div>
                </Grid>
                <Grid item xs={6}>
                    <div>Customers</div>
                    <div>FAQ</div>
                    <div>Contact us</div>
                    <div>Referral</div>
                </Grid>
                <Grid item xs={6}>
                    <div>Legal</div>
                    <div>Terms of Service</div>
                    <div>Subscription Terms</div>
                    <div>Privacy</div>
                </Grid>
            </Grid>
            <div>
                <CaroosyLogo />
            </div>
        </FlexColumn>
    );
};

export default PageFooter;
