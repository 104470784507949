// MUI
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface WindowsLocationType {
    from: Location | undefined;
}

export default function SignInPage() {
    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();

    const location = useLocation();
    const navigate = useNavigate();

    const state = location.state as WindowsLocationType | undefined;
    const from = state?.from?.pathname || '/';

    // https://gdpr.eu/cookies/
    // State variable to control whether to show the cookie consent modal.
    // const [loginErrorMsg, setLoginErrorMsg] = React.useState<string>('');

    //	TODO: Remove the following comment
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleSignIn = async () => {
        if (email && password) {
            try {
                const auth = getAuth();
                await signInWithEmailAndPassword(auth, email, password);

                // Send them back to the page they tried to visit when they were
                // redirected to the login page. Use { replace: true } so we don't create
                // another entry in the history stack for the login page.  This means that
                // when they get to the protected page and click the back button, they
                // won't end up back on the login page, which is also really nice for the
                // user experience.
                navigate(from, { replace: true });
            } catch (error: unknown) {
                console.log(error);
            }
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                height: '100vh',
                width: '100vw',
                backgroundColor: '#CCC',
            }}
        >
            <Box
                sx={{
                    flex: 1,
                    maxWidth: "300px",
                    backgroundColor: '#FB8E68',
                }}
            />
            <Box
                sx={{
                    flex: 1,
                    height: '100%',
                    backgroundColor: '#fff',
                    display: 'flex',
                    alignContent: 'center',
                }}
            >
                <Grid container spacing={2} sx={{ margin: 'auto', padding: 7 }}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="E-mail"
                            onChange={e => {
                                setEmail(e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Password"
                            type={'password'}
                            onChange={e => {
                                setPassword(e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" fullWidth color="success" onClick={() => handleSignIn()}>
                            Login
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}
