import { styled } from '@mui/material';

import CaroosyImage from '../../assets/caroosy_logo.png';


export const CaroosyLogo = styled('div')({
    backgroundImage: `url(${CaroosyImage})`,
    backgroundSize: 'contain',
    width: '150px',
    height: '30px',
    margin: 'auto 0',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
});

