import React, { createContext, useEffect, useState } from 'react';
import { getAllVehiclesFromFirestore } from './Firebase';
import { test_vehicles } from './test_data';
// --------------------------------------------------------
// Context to hold App Data.

export interface Vehicle {
    id: number;
    ahb_id: string;
    brand: string;
    segment?: string;
    category?: string;
    tags?: string;
    name: string;
    body_type?: string;
    powertrain?: string;
    engine_variant?: string;
    transmission?: string;
    model_variant?: string;
    price_buy?: number;
    price_lease?: number;
    time_lease?: number;
    km_lease?: number;
    price_subscribe?: number;
    time_subscribe?: number;
    km_subscribe?: number;
    url_buy?: string;
    url_lease?: string;
    url_subscribe?: string;
    images?: string;
}

export interface MatchResult {
    vehicle_id: number;
    title: string;
    image: string;
    tags: string[];
    accuracy: number;
}

export interface SurveyParams {
    ahb_id?: string;
    vehicle_name?: string;
    brand?: string;
    city?: string;
    food?: string;
    activity?: string;
    feature?: string;
    fashion?: string;
    interior?: string;
    road?: string;
    date?: string;
    author?: string;
}

const default_vehicles: Vehicle[] = test_vehicles as Vehicle[];

interface CaroosyCtxProps {
    vehicles: Vehicle[];
    setVehicles: (vehicles: Vehicle[]) => void;

    selectedVehicle: Vehicle | undefined;
    setSelectedVehicle: (vehicle: Vehicle) => void;

    surveyParams: SurveyParams | undefined;
    setSurveyParams: (surveyParams: SurveyParams) => void;
}

export const CaroosyDataCtx = createContext({} as CaroosyCtxProps);

export const CaroosyDataProvider: React.FC = ({ children }) => {
    const [vehicles, setVehicles] = useState<Vehicle[]>(default_vehicles);
    const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | undefined>();
    const [surveyParams, setSurveyParams] = useState<SurveyParams | undefined>();

    useEffect(() => {
        const fetch_data = async () => {
            const vehicles = await getAllVehiclesFromFirestore();
            setVehicles(vehicles);
        };
        fetch_data();
    }, []);

    return (
        <CaroosyDataCtx.Provider
            value={{
                vehicles: vehicles,
                setVehicles: setVehicles,
                selectedVehicle: selectedVehicle,
                setSelectedVehicle: setSelectedVehicle,
                surveyParams: surveyParams,
                setSurveyParams: setSurveyParams,
            }}
        >
            {children}
        </CaroosyDataCtx.Provider>
    );
};
