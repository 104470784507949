import { styled } from '@mui/material';

export const OrangeButton = styled('div')({
    background: '#FB8E68',
    backgroundSize: 'contain',
    width: '150px',
    height: '40px',
    cursor: 'pointer',
    display: 'flex',
    color: 'white',
    fontWeight: 600,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
});

export const WhiteButton = styled('div')({
    background: 'white',
    backgroundSize: 'contain',
    width: '150px',
    height: '40px',
    cursor: 'pointer',
    display: 'flex',
    color: '#FB8E68',
    border: "2px solid #FB8E68",
    fontWeight: 600,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
});
