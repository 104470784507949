import { FC } from 'react';
import { FlexRow } from './Flex/Flex';

interface NextButtonProps {
    onClick?: () => void;
    label: string;
}

export const NextButton: FC<NextButtonProps> = ({ label, onClick }) => {
    return (
        <FlexRow
            sx={{
                position: 'fixed',
                zIndex: 20,
                bottom: 0,
                right: 0,
                left: 0,
                background: 'black',
                maxHeight: '60px',
                color: 'white',
                lineHeight: '60px',
                justifyContent: 'center',
                fontSize: '1rem',
                cursor: 'pointer',
                fontWeight: '700',
                fontFamily: "'Nanum Gothic', sans-serif",
                transition: '0.5s ease',
                '&:hover': {
                    background: '#FB8E68',
                },
                '&:active': {
                    background: '#666',
                },
            }}
            onClick={onClick}
        >
            {label}
        </FlexRow>
    );
};

export default NextButton;
