import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

import { Alert, Container, Grid, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FlexColumn, FlexRow } from '../../components/Flex/Flex';

import Tesla from '../../assets/tesla.png';
import PageLayout from '../PageLayout/PageLayout';
import { useContext, useEffect, useState } from 'react';
import { firestore } from '../../Firebase';
import { CaroosyDataCtx } from '../../DataProvider';
import { OrangeButton, WhiteButton } from '../../components/Button/Button';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';

interface SurveySubmitPageProps {}
export interface SnackbarState extends SnackbarOrigin {
    open: boolean;
}

const Page: React.FC<SurveySubmitPageProps> = ({}) => {
    const navigate = useNavigate();
    const [state, setState] = useState<SnackbarState>({
        open: false,
        vertical: 'top',
        horizontal: 'right',
    });
    const { vertical, horizontal, open } = state;

    const { surveyParams } = useContext(CaroosyDataCtx);

    const [message, setMessage] = useState('Data ready for submission');
    const [author, setAuthor] = useState<string | undefined>();
    const [done, setDone] = useState(false);
    const [error, setError] = useState(false);

    const submitSurveyData = async () => {
        if (!author || author === '') {
            setError(true);
            return;
        }
        if (done) {
            return;
        }
        console.log({
            ...surveyParams,
            timestamp: serverTimestamp(),
        });
        await addDoc(collection(firestore, 'survey_snapshots'), {
            ...surveyParams,
            timestamp: serverTimestamp(),
            author: author,
        });
        setDone(true);
        setMessage('Done');
        setState({ ...state, open: true });
        setTimeout(() => {
            setState({ ...state, open: false });
        }, 2000);
    };

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    useEffect(() => {
        if (!surveyParams) {
            navigate('/');
        }
    }, [surveyParams, navigate]);

    return (
        <PageLayout scrollable displayFindCarButton>
            <Container
                maxWidth="sm"
                style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h1">{message}</Typography>

                {!done && (
                    <TextField
                        error={error}
                        required
                        id="outlined-required"
                        label="Author"
                        sx={{ minWidth: '300px', marginTop: '30px' }}
                        onChange={e => {
                            setError(false);
                            setAuthor(e.target.value);
                        }}
                    />
                )}
                {!done && (
                    <OrangeButton
                        sx={{ marginTop: '30px', background: done ? '#888' : '#FB8E68' }}
                        onClick={() => {
                            submitSurveyData();
                        }}
                    >
                        SUBMIT
                    </OrangeButton>
                )}

                {<WhiteButton sx={{ marginTop: '20px' }}>SKIP</WhiteButton>}
            </Container>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={handleClose}
                key={vertical + horizontal}
            >
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    This is a success message!
                </Alert>
            </Snackbar>
        </PageLayout>
    );
};

export default Page;
