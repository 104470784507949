export const test_vehicles = [
    {
        id: 0,
        ahb_id: 'VOLKSWAGEN§GOLD',
        brand: 'VW',
        segment: 'Volume',
        category: 'Car',
        tags: 'Komfort Essential, Nur das wichtigste drin',
        name: 'Golf',
        body_type: 'Hatchback',
        powertrain: 'Petrol',
        engine_variant: ' 1.5 TSI OPF',
        transmission: 'Automatik',
        model_variant: 'Life',
        price_buy: 30120,
        price_lease: 362,
        time_lease: 24,
        km_lease: 10000,
        price_subscribe: 439,
        time_subscribe: 6,
        km_subscribe: 15000,
        url_buy:
            'https://www.volkswagen.de/de/konfigurator.html/__app/der-neue-golf/der-neue-golf/life.app?category-app=private&carlineId-app=30310&exteriorId-app=F14+5K5K&options-app=GPB4PB4-MEPH7X5-MKSUKA2-MHKAKH7&interiorId-app=F56+++++BD&modelId-app=CD13GX%24GYORYOR&modelVersion-app=0&modelYear-app=2022&salesGroupId-app=32350&trimName-app=Life&buildabilityStatus-app=buildable',
        url_lease:
            'https://www.volkswagen.de/de/konfigurator.html/__app/der-neue-golf/der-neue-golf/life.app?buildabilityStatus-app=buildable&category-app=private&carlineId-app=30310&salesGroupId-app=32350&trimName-app=Life&modelId-app=CD13GX%24GYORYOR&modelVersion-app=0&modelYear-app=2022&exteriorId-app=F14+5K5K&interiorId-app=F56+++++BD&options-app=GPB4PB4-MEPH7X5-MKSUKA2-MHKAKH7',
        url_subscribe: 'https://www.cluno.com/de/portfolio/vw-golf-limousine-317/',
        images: 'VW_Golf_Life_Petrol.png',
    },
];
