import { Container, Grid } from '@mui/material';
import { FlexColumn, FlexRow } from '../../components/Flex/Flex';
import { useParams, useSearchParams } from 'react-router-dom';
import PageLayout from '../PageLayout/PageLayout';

import IntroPage from '../s0_intro_page/IntroPage';

// City Images
import BerlinImage from '../../assets/images/cities/berlin.png';
import CapeTownImage from '../../assets/images/cities/cape_town.png';
import FlorenceImage from '../../assets/images/cities/florence.png';
import NewYorkImage from '../../assets/images/cities/new_york.png';
import ParisImage from '../../assets/images/cities/paris.png';
import RioDeJaneiro from '../../assets/images/cities/rio_de_janeiro.png';
import SanFrancisco from '../../assets/images/cities/san_francisco.png';
import Venedig from '../../assets/images/cities/venedig.png';

// Feature Images
import comfortImage from '../../assets/images/features/comfort.png';
import connectivityImage from '../../assets/images/features/connectivity.png';
import designImage from '../../assets/images/features/design.png';
import entertainmentImage from '../../assets/images/features/entertainment.png';
import safetyImage from '../../assets/images/features/safety.png';
import spaceImage from '../../assets/images/features/space.png';
import speedImage from '../../assets/images/features/speed.png';
import sustainabilityImage from '../../assets/images/features/sustainability.png';

// Food Images
import africanImage from '../../assets/images/food/african.png';
import americanImage from '../../assets/images/food/american.png';
import asianImage from '../../assets/images/food/asian.png';
import germanImage from '../../assets/images/food/german.png';
import italianImage from '../../assets/images/food/italian.png';
import meditarreanImage from '../../assets/images/food/mediterranean.png';
import mexicanImage from '../../assets/images/food/mexican.png';
import veganImage from '../../assets/images/food/vegan.png';

// Food Images
import concertImage from '../../assets/images/activities/concert.png';
import cookingImage from '../../assets/images/activities/cooking.png';
import museumImage from '../../assets/images/activities/museum.png';
import nightlifeImage from '../../assets/images/activities/nightlife.png';
import outdoorImage from '../../assets/images/activities/outdoor.png';
import readingImage from '../../assets/images/activities/reading.png';
import sportImage from '../../assets/images/activities/sport.png';
import wellnessImage from '../../assets/images/activities/wellness.png';

// Fashion Images
import businessCasualImage from '../../assets/images/fashion/business_casual.png';
import businessImage from '../../assets/images/fashion/business.png';
import casualImage from '../../assets/images/fashion/casual.png';
import modernChicImage from '../../assets/images/fashion/modern_chic.png';
import sportyImage from '../../assets/images/fashion/sporty.png';
import streetwearImage from '../../assets/images/fashion/streetwear.png';

// Living Images
import alternativeLivingImage from '../../assets/images/living/alternative.jpeg';
import classicLivingImage from '../../assets/images/living/classic.jpeg';
import luxuryLivingImage from '../../assets/images/living/luxury.png';
import modernLivingImage from '../../assets/images/living/modern.jpeg';
import retroLivingImage from '../../assets/images/living/retro.jpeg';
import ruralLivingImage from '../../assets/images/living/rural.jpeg';

// Road Images
import cityImage from '../../assets/images/road/city.png';
import countryRoadImage from '../../assets/images/road/country_road.png';
import highwayImage from '../../assets/images/road/highway.png';
import offroadImage from '../../assets/images/road/offroad.png';

import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { AuthCtx } from '../../AuthProvider';
import NextButton from '../../components/NextButton';
import { CaroosyDataCtx, Vehicle } from '../../DataProvider';

const lsTiles = [
    { id: 0, lsStep: 0, category: 'city', title: 'Berlin', image: BerlinImage },
    { id: 1, lsStep: 0, category: 'city', title: 'Cape Town', image: CapeTownImage },
    { id: 2, lsStep: 0, category: 'city', title: 'Florence', image: FlorenceImage },
    { id: 3, lsStep: 0, category: 'city', title: 'New York', image: NewYorkImage },
    { id: 4, lsStep: 0, category: 'city', title: 'Paris', image: ParisImage },
    { id: 5, lsStep: 0, category: 'city', title: 'Rio de Janeiro', image: RioDeJaneiro },
    { id: 6, lsStep: 0, category: 'city', title: 'San Francisco', image: SanFrancisco },
    { id: 7, lsStep: 0, category: 'city', title: 'Venedig', image: Venedig },
    { id: 0, lsStep: 1, category: 'feature', title: 'Comfort', image: comfortImage },
    { id: 1, lsStep: 1, category: 'feature', title: 'Connectivity', image: connectivityImage },
    { id: 2, lsStep: 1, category: 'feature', title: 'Design', image: designImage },
    { id: 3, lsStep: 1, category: 'feature', title: 'Entertainment', image: entertainmentImage },
    { id: 4, lsStep: 1, category: 'feature', title: 'Safety', image: safetyImage },
    { id: 5, lsStep: 1, category: 'feature', title: 'Space', image: spaceImage },
    { id: 6, lsStep: 1, category: 'feature', title: 'Speed', image: speedImage },
    { id: 7, lsStep: 1, category: 'feature', title: 'Sustainability', image: sustainabilityImage },
    { id: 0, lsStep: 2, category: 'food', title: 'African', image: africanImage },
    { id: 1, lsStep: 2, category: 'food', title: 'American', image: americanImage },
    { id: 2, lsStep: 2, category: 'food', title: 'Asian', image: asianImage },
    { id: 3, lsStep: 2, category: 'food', title: 'German', image: germanImage },
    { id: 4, lsStep: 2, category: 'food', title: 'Italian', image: italianImage },
    { id: 5, lsStep: 2, category: 'food', title: 'Meditarrean', image: meditarreanImage },
    { id: 6, lsStep: 2, category: 'food', title: 'Mexican', image: mexicanImage },
    { id: 7, lsStep: 2, category: 'food', title: 'Vegan', image: veganImage },
    { id: 0, lsStep: 3, category: 'fashion', title: 'Business Casual', image: businessCasualImage },
    { id: 1, lsStep: 3, category: 'fashion', title: 'Business', image: businessImage },
    { id: 2, lsStep: 3, category: 'fashion', title: 'Casual', image: casualImage },
    { id: 3, lsStep: 3, category: 'fashion', title: 'Modern Chic', image: modernChicImage },
    { id: 4, lsStep: 3, category: 'fashion', title: 'Sporty', image: sportyImage },
    { id: 5, lsStep: 3, category: 'fashion', title: 'Streetwear', image: streetwearImage },
    { id: 0, lsStep: 4, category: 'interior', title: 'Alternative', image: alternativeLivingImage },
    { id: 1, lsStep: 4, category: 'interior', title: 'Classic', image: classicLivingImage },
    { id: 2, lsStep: 4, category: 'interior', title: 'Luxury', image: luxuryLivingImage },
    { id: 3, lsStep: 4, category: 'interior', title: 'Modern', image: modernLivingImage },
    { id: 4, lsStep: 4, category: 'interior', title: 'Retro', image: retroLivingImage },
    { id: 5, lsStep: 4, category: 'interior', title: 'Rural', image: ruralLivingImage },
    { id: 0, lsStep: 5, category: 'activity', title: 'Concert', image: concertImage },
    { id: 1, lsStep: 5, category: 'activity', title: 'Cooking', image: cookingImage },
    { id: 2, lsStep: 5, category: 'activity', title: 'Museum', image: museumImage },
    { id: 3, lsStep: 5, category: 'activity', title: 'Nightlife', image: nightlifeImage },
    { id: 4, lsStep: 5, category: 'activity', title: 'Outdoor', image: outdoorImage },
    { id: 5, lsStep: 5, category: 'activity', title: 'Reading', image: readingImage },
    { id: 6, lsStep: 5, category: 'activity', title: 'Sport', image: sportImage },
    { id: 7, lsStep: 5, category: 'activity', title: 'Wellness', image: wellnessImage },
    { id: 0, lsStep: 6, category: 'road', title: 'City', image: cityImage },
    { id: 1, lsStep: 6, category: 'road', title: 'CountryRoad', image: countryRoadImage },
    { id: 2, lsStep: 6, category: 'road', title: 'Highway', image: highwayImage },
    { id: 3, lsStep: 6, category: 'road', title: 'Offroad', image: offroadImage },
];

interface LSPageProps {
    onNextButtonPressed?: () => void;
}

interface LifeStyleTileProps {
    title?: string;
    img?: string;
    onClick?: () => void;
    surveyVehicle?: Vehicle;
    category?: string;
}

const LifeStyleTile: React.FC<LifeStyleTileProps> = ({ title, img, onClick, category }) => {
    return (
        <Grid
            item
            xs={6}
            sm={category === 'fashion' || category === 'interior' ? 4 : 3}
            sx={{
                backgroundImage: img ? `url(${img})` : 'none',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                position: 'relative',
                cursor: 'pointer',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                    transform: 'scale(1.04)',
                    zIndex: 10,
                },
            }}
            onClick={onClick}
        >
            <div
                style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: '40px',
                    color: 'white',
                    paddingLeft: '10px',
                    fontFamily: "'Nanum Gothic', sans-serif",
                    fontSize: '1.3rem',
                    fontWeight: 600,
                    backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)',
                }}
            >
                {title}
            </div>
        </Grid>
    );
};

const getQuestionMessageForStep = (category: string) => {
    switch (category) {
        case 'city':
            return (
                <p
                    style={{
                        fontSize: '3.5rem',
                        fontWeight: '400',
                        lineHeight: '3.7rem',
                    }}
                >
                    What{' '}
                    <span style={{ background: '#FB8E68', fontWeight: '800', letterSpacing: '2px' }}>city vibe</span>{' '}
                    appeals best <br /> to you?
                </p>
            );
        case 'food':
            return (
                <p
                    style={{
                        fontSize: '3.5rem',
                        fontWeight: '400',
                        lineHeight: '3.7rem',
                    }}
                >
                    Which{' '}
                    <span style={{ background: '#FB8E68', fontWeight: '800', letterSpacing: '2px' }}>cuisine</span> do
                    you like the <br />
                    most?
                </p>
            );
        case 'activity':
            return (
                <p
                    style={{
                        fontSize: '3.5rem',
                        fontWeight: '400',
                        lineHeight: '3.7rem',
                    }}
                >
                    What is your favorite
                    <br />
                    <span style={{ background: '#FB8E68', fontWeight: '800', letterSpacing: '2px' }}>activity</span>?
                </p>
            );
        case 'feature':
            return (
                <p
                    style={{
                        fontSize: '3.5rem',
                        fontWeight: '400',
                        lineHeight: '3.7rem',
                    }}
                >
                    What is{' '}
                    <span style={{ background: '#FB8E68', fontWeight: '800', letterSpacing: '2px' }}>
                        most important
                    </span>{' '}
                    to
                    <br />
                    you in your car?
                </p>
            );
        case 'fashion':
            return (
                <p
                    style={{
                        fontSize: '3.5rem',
                        fontWeight: '400',
                        lineHeight: '3.7rem',
                    }}
                >
                    Which{' '}
                    <span style={{ background: '#FB8E68', fontWeight: '800', letterSpacing: '2px' }}>fashion</span> type
                    are you?
                </p>
            );
        case 'interior':
            return (
                <p
                    style={{
                        fontSize: '3.5rem',
                        fontWeight: '400',
                        lineHeight: '3.7rem',
                    }}
                >
                    Which{' '}
                    <span style={{ background: '#FB8E68', fontWeight: '800', letterSpacing: '2px' }}>
                        interior style
                    </span>{' '}
                    do you like the most?
                </p>
            );
        case 'road':
            return (
                <p
                    style={{
                        fontSize: '3.5rem',
                        fontWeight: '400',
                        lineHeight: '3.7rem',
                    }}
                >
                    Which{' '}
                    <span style={{ background: '#FB8E68', fontWeight: '800', letterSpacing: '2px' }}>
                        type of roads
                    </span>{' '}
                    do you <br /> use regularly?
                </p>
            );
        default:
            return <p>Error</p>;
    }
};

const Page: React.FC<LSPageProps> = ({ onNextButtonPressed }) => {
    let { surveyParams, setSurveyParams } = useContext(CaroosyDataCtx);
    let { category } = useParams();

    let [searchParams] = useSearchParams();
    let [lsStep, setLsStep] = useState(0);

    // searchParams is a URLSearchParams object.
    // See https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
    let isSurvey = searchParams.get('svy');
    let queryVehicle = searchParams.get('vehicle');

    const { user } = useContext(AuthCtx);
    const navigate = useNavigate();
    const handleNext = () => {
        if (onNextButtonPressed) {
            onNextButtonPressed();
        }
    };

    const handleLifeStyleImageSelected = (option: string) => {
        const surveyParam = isSurvey === 'true' ? '?svy=true' : '';
        const vehicleParam = isSurvey === 'true' && queryVehicle ? `&vehicle=${queryVehicle}` : '';

        if (category === 'city') {
            setSurveyParams({ ...surveyParams, city: option });
            navigate(`/ls/food${surveyParam}${vehicleParam}`);
        } else if (category === 'food') {
            setSurveyParams({ ...surveyParams, food: option });
            navigate(`/ls/activity${surveyParam}${vehicleParam}`);
        } else if (category === 'activity') {
            setSurveyParams({ ...surveyParams, activity: option });
            navigate(`/ls/fashion${surveyParam}${vehicleParam}`);
        } else if (category === 'fashion') {
            setSurveyParams({ ...surveyParams, fashion: option });
            navigate(`/ls/interior${surveyParam}${vehicleParam}`);
        } else if (category === 'interior') {
            setSurveyParams({ ...surveyParams, fashion: option });
            navigate(`/ls/feature${surveyParam}${vehicleParam}`);
        } else if (category === 'feature') {
            setSurveyParams({ ...surveyParams, feature: option });
            navigate(`/ls/road${surveyParam}${vehicleParam}`);
        } else {
            if (isSurvey === 'true') {
                console.log(surveyParams);
                return;
            } else {
                navigate('/hard-facts');
            }
        }
    };

    useEffect(() => {
        if (category === 'city') {
            setLsStep(1);
        } else if (category === 'food') {
            setLsStep(2);
        } else if (category === 'activity') {
            setLsStep(3);
        } else if (category === 'feature') {
            setLsStep(4);
        } else if (category === 'interior') {
            setLsStep(5);
        } else if (category === 'fashion') {
            setLsStep(6);
        } else if (category === 'road') {
            setLsStep(7);
        }
    }, [category, setLsStep]);

    if (category === 'intro') {
        return <IntroPage isSurvey={isSurvey === 'true'} />;
    }

    return (
        <PageLayout>
            <FlexColumn sx={{ marginBottom: isSurvey && lsStep === 5 ? '60px' : '0px' }}>
                <Container maxWidth="md" style={{ padding: '0px', position: 'relative', flex: 1, display: 'flex' }}>
                    <FlexColumn sx={{ fontFamily: "'Nanum Gothic', sans-serif" }}>
                        {getQuestionMessageForStep(category || 'city')}
                    </FlexColumn>
                    <FlexColumn
                        sx={{
                            maxWidth: '70px',
                            justifyContent: 'center',
                            fontFamily: "'Nanum Gothic', sans-serif",
                            fontWeight: 600,
                        }}
                    >
                        Step {lsStep || 0} / 7
                    </FlexColumn>
                </Container>
                <FlexColumn sx={{ position: 'relative', flex: 3 }}>
                    <Grid container sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                        {lsTiles
                            .filter(t => t.category === category)
                            .map((tileData, index) => {
                                return (
                                    <LifeStyleTile
                                        key={'key' + index}
                                        img={tileData.image}
                                        title={tileData.title}
                                        category={category}
                                        onClick={() => {
                                            handleLifeStyleImageSelected(tileData.title);
                                        }}
                                    />
                                );
                            })}
                    </Grid>
                </FlexColumn>
            </FlexColumn>
            {user && isSurvey === 'true' && lsStep === 7 && (
                <NextButton label={'Save'} onClick={() => navigate('/survey')} />
            )}
        </PageLayout>
    );
};

export default Page;
