import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './AuthProvider';
// FIREBASE
// Importing the firebase module will initialize the firebase app client
import './Firebase';
// STYLES
import NotFoundPage from './pages/NotFoundPage';
import SignInPage from './pages/SignInPage';
import reportWebVitals from './reportWebVitals';
import SecureLayout from './SecureLayout';

// CUSTOM PAGES
import IndexPage from './pages/Index';
import LifeStyleSelectionPage from './pages/s1_lifestyle_page/LifeStyleSelectionPage';
import HardFactsPage from './pages/s2_hard_facts_page/HardFactsPage';
import ResultPage from './pages/s3_result_page/ResultPage';
import DetailsPage from './pages/s4_details_page/DetailsPage';

// MUI
import theme from './theme';
import { CaroosyDataProvider } from './DataProvider';
import SurveySubmitPage from './pages/SurveyPage/SurveySubmitPage';

ReactDOM.render(
    <React.StrictMode>
        <AuthProvider>
            <CaroosyDataProvider>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <BrowserRouter>
                        <Routes>
                            {/* //////////////////////////////////////////////////////////////////////// */}
                            {/* ////                               SignIn                           //// */}
                            {/* //////////////////////////////////////////////////////////////////////// */}

                            <Route path="/login" element={<SignInPage />} />
                            <Route
                                path="/"
                                element={
                                    <SecureLayout>
                                        <IndexPage />
                                    </SecureLayout>
                                }
                            />

                            {/* //////////////////////////////////////////////////////////////////////// */}
                            {/* ////                             Lifestyle                          //// */}
                            {/* //////////////////////////////////////////////////////////////////////// */}

                            <Route
                                path="/ls/:category"
                                element={
                                    <SecureLayout>
                                        <LifeStyleSelectionPage />
                                    </SecureLayout>
                                }
                            />

                            {/* //////////////////////////////////////////////////////////////////////// */}
                            {/* ////                               SURVEY                           //// */}
                            {/* //////////////////////////////////////////////////////////////////////// */}

                            <Route
                                path="/survey"
                                element={
                                    <SecureLayout>
                                        <SurveySubmitPage />
                                    </SecureLayout>
                                }
                            />

                            {/* //////////////////////////////////////////////////////////////////////// */}
                            {/* ////                             HARD FACTS                          //// */}
                            {/* //////////////////////////////////////////////////////////////////////// */}

                            <Route
                                path="/hard-facts"
                                element={
                                    <SecureLayout>
                                        <HardFactsPage />
                                    </SecureLayout>
                                }
                            />

                            {/* //////////////////////////////////////////////////////////////////////// */}
                            {/* ////                            RESULT PAGE                         //// */}
                            {/* //////////////////////////////////////////////////////////////////////// */}

                            <Route
                                path="/result"
                                element={
                                    <SecureLayout>
                                        <ResultPage />
                                    </SecureLayout>
                                }
                            />

                            {/* //////////////////////////////////////////////////////////////////////// */}
                            {/* ////                            CAR DETAILS                         //// */}
                            {/* //////////////////////////////////////////////////////////////////////// */}

                            <Route
                                path="/details/:id"
                                element={
                                    <SecureLayout>
                                        <DetailsPage />
                                    </SecureLayout>
                                }
                            />

                            {/* //////////////////////////////////////////////////////////////////////// */}
                            {/* ////                          NOT FOUND PAGE                        //// */}
                            {/* //////////////////////////////////////////////////////////////////////// */}
                            <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                    </BrowserRouter>
                </ThemeProvider>
            </CaroosyDataProvider>
        </AuthProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
