import * as React from 'react';

function SVG(props) {
    return (
        <svg viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_878_24270)" >
                <path
                    d="M15.5833 2.4248L15.5833 15.1748C15.5833 15.3627 15.5087 15.5428 15.3759 15.6757C15.243 15.8085 15.0629 15.8831 14.875 15.8831L11.3333 15.8831L11.3333 17.2998L14.875 17.2998C15.4386 17.2998 15.9791 17.0759 16.3776 16.6774C16.7761 16.2789 17 15.7384 17 15.1748L17 2.4248C17 1.86122 16.7761 1.32072 16.3776 0.922203C15.9791 0.523687 15.4386 0.299805 14.875 0.299805L11.3333 0.299804L11.3333 1.71647L14.875 1.71647C15.0629 1.71647 15.243 1.7911 15.3759 1.92394C15.5087 2.05677 15.5833 2.23694 15.5833 2.4248Z"
                />
                <path
                    d="M0.620903 10.3024L3.86931 13.5508L4.8709 12.5492L1.85057 9.52886L13.458 9.50832L13.458 8.09165L1.8109 8.11219L4.87231 5.05077L3.87073 4.04919L0.62232 7.29761C0.223756 7.69592 -0.000292313 8.23622 -0.000558438 8.79969C-0.000823609 9.36317 0.222716 9.90368 0.620903 10.3024Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_878_24270">
                    <rect width="17" height="17" transform="translate(17 17.2998) rotate(-180)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default SVG;
