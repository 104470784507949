import { getAuth, ParsedToken, signOut, User } from 'firebase/auth';
import React, { createContext } from 'react';

// --------------------------------------------------------
// Context to hold Authenticated user.

enum UserAuthRoles {
    Admin = 'admin',
    PmoConsultant = 'pmo_consultant',
    PmoClient = 'pmo_client',
    viewers = 'viewers',
}

interface IAuthStateProps {
    user: User | null;
    customClaims: ParsedToken | null;
    loading: boolean;
    permissions: string[];
    role: UserAuthRoles;
    signOut: () => void;
}

export const AuthCtx = createContext({} as IAuthStateProps);

export const AuthProvider: React.FC = ({ children }) => {
    const [user, setUser] = React.useState<User | null>(null);
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [permissions, setPermissions] = React.useState<string[]>([]);
    const [customClaims, setCustomClaims] = React.useState<ParsedToken | null>(null);
    const [loadingAuth, setLoadingAuth] = React.useState<boolean>(true);

    const handleSignOut = async () => {
        const auth = getAuth();
        await signOut(auth);
    };

    React.useEffect(() => {
        const auth = getAuth();
        const unsubscribe = auth.onAuthStateChanged(async firebaseUser => {
            if (firebaseUser) {
                const idTokenResult = await auth.currentUser!.getIdTokenResult(true);
                const claims = idTokenResult.claims;
                setCustomClaims(claims);
                if (claims['permissions']) {
                    const permissions = claims['permissions'] as string | undefined;
                    if (permissions) {
                        setPermissions(permissions.split(','));
                    }
                }
            }
            setUser(firebaseUser);
            setLoadingAuth(false);
        });

        return unsubscribe;
    }, []);
    return (
        <AuthCtx.Provider
            value={{
                user: user,
                loading: loadingAuth,
                customClaims: customClaims,
                permissions: permissions,
                role: UserAuthRoles.viewers,
                signOut: handleSignOut,
            }}
        >
            {children}
        </AuthCtx.Provider>
    );
};
